/*! ===================================
 *  Author: Nazarkin Roman, Egor Dankov
 *  -----------------------------------
 *  PuzzleThemes
 *  =================================== */

'use strict';

/***********************************************
 * Portfolio shuffle
 ***********************************************/
(function ($) {
    // locate what we want to sort
    var $blocks = $('.sp-portfolio-block');

    // don't run this function if this page does not contain required element
    if ($blocks.length <= 0) {
        return;
    }

    // init shuffle and filters
    $blocks.each(function () {
        var $this = $(this),
            $grid = $this.find('.sp-portfolio-items'),
            $filterBtns = $this.find('.sp-portfolio-sorting a[data-group]');

        // instantiate the plugin
        $grid.pzt_shuffle({
            itemSelector: '[class*="col-"]',
            gutterWidth : 0,
            speed       : 600, // transition/animation speed (milliseconds).
            easing      : 'ease'
        });

        // init filters
        $filterBtns.on('click', function (e) {
            var $this = $(this);

            // hide current label, show current label in title
            $this.parent().siblings().removeClass('active');
            $this.parent().addClass('active');

            // filter elements
            $grid.shuffle('shuffle', $this.data('group'));

            e.preventDefault();
        });
    });
})(jQuery);